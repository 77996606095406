import React from "react";
import Card from "../components/Card";
import { SiteData } from "../Data/SiteData";

function Portfolio() {
  return (
    <div>
      <div className="mb-6">
        <h1 className="brand text-4xl mb-2">Recent Works</h1>
        <p>Here is a porfolio of some of my work.</p>
      </div>

      <div className="sm:grid sm:grid-cols-2 sm:gap-4 lg:grid-cols-3 md:gap-4 ">
        {SiteData.map(({ projects }) =>
          projects.map((item, index) => (
            <Card id={item.id} name={item.name} image_url={item.image_url} key={index} description={item.description} stack={item.stack} />
          ))
        )}
      </div>
    </div>
  );
}

export default Portfolio;
