import {HashRouter as Router, Routes, Route } from "react-router-dom";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar/Navbar";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import Portfolio from "./pages/Portfolio";
import PortfolioItem from "./pages/PortfolioItem";
import Resume from "./pages/Resume";

function App() {
  return ( 
    <>
      <Router>
        <div className="flex-col justify-between h-screen">
          <Navbar />
          <main className="container mx-auto p-5 allButFooter">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/resume" element={<Resume />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/portfolio/:id" element={<PortfolioItem/>} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </main>
            <Footer className="absolute bottom-0"/>
        </div>
      </Router>
    </>
  );
}

export default App;
