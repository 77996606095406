import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SiteData } from "../Data/SiteData";
import { Link } from 'react-router-dom'
import {BiArrowBack} from "react-icons/bi"
import {AiFillGithub} from "react-icons/ai"
import {MdLiveTv} from "react-icons/md"


function PortfolioItem() {
  const [data, setData] = useState(null);
  let { id } = useParams();


  useEffect(() => {
    const datas = SiteData[0].projects.filter((item) => item.id === parseInt(id))[0];
    setData(datas);
  }, [id]);

  return (
    data && (
      <main className="mt-12">
        <Link to="/portfolio" className="bg-black w-24 text-white text-sm leading-6 font-medium py-2 px-3 rounded-lg my-8 flex items-center gap-4"><span><BiArrowBack /></span>Back</Link>
        <div className="flex flex-col lg:flex-row gap-8 items-between">
          <div className="w-full ">
            <img src={`${data.image_url}`} className="object-cover" alt="project_gif_url"/>
          </div>
          
          <div className="flex flex-col items-start gap-10 w-full ">
            <h1 className="uppercase tracking-widest font-bold text-2xl">{data.name}</h1>
              <p>{data.description}</p>
              <div className="flex w-full flex-row items-center justify-center gap-4 ">
                <a href={`${data.live_url}`} rel="noreferrer"  target="_blank"className="bg-green-400 text-white text-sm leading-6 py-2 px-3 w-40 rounded-lg  flex items-center justify-center gap-4 "> <MdLiveTv/>View Live</a>
                <a href={`${data.github_url}`} rel="noreferrer" target="_blank" className="bg-black text-white text-sm leading-6 py-2 px-3 w-40 rounded-lg flex items-center justify-center gap-4"> <AiFillGithub/> View on GitHub</a>
              </div>
          </div>
        </div>
      </main>
    )
  );
}

export default PortfolioItem;
