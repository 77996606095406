import React from 'react'

function PageNotFound() {
  return (
    <div className="flex flex-col justify-center items-center m-auto">
      <h1 className="text-lg font-bold">Error...Page Not found</h1>
    </div>
  )
}

export default PageNotFound
