import { useState } from "react";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { SideBarData } from "./SideBarData";
import "./Navbar.css";

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const showSideBar = () => setSidebar(!sidebar);

  return (
    <>
      <div className="navbar bg-base-100 drop-shadow-xl">
        <div className="container mx-auto flex justify-between">
          <div>
            <Link to="/" className="font-mono font-bold text-3xl">E N</Link>
          </div>

          <div className="flex justify-content items-center">
            <div className="md:hidden">
              <Link to="#">
                <FaBars className="text-2xl" onClick={showSideBar} />
              </Link>
            </div>
            <div className="flex gap-3 hidden md:block">
            <Link className="btn btn-ghost normal-case" to="/">Home</Link>
            <Link className="btn btn-ghost normal-case" to="/about">About </Link>
            <Link className="btn btn-ghost normal-case" to="/resume">Resume </Link>
            <Link className="btn btn-ghost normal-case" to="/portfolio">Portfolio </Link>
            <Link className="btn btn-ghost normal-case" to="/contact">Contact </Link>
          </div>
          </div>
        </div>
      </div>

      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <ul className="nav-menu-items">
          <li className="navbar-toggle text-2xl">
            <Link to="#">
              <AiOutlineClose onClick={showSideBar} />
            </Link>
          </li>
          {SideBarData.map((item, index) => (
            <li key={index} className={item.cName}>
              <Link to={item.path} onClick={showSideBar}>
                {item.icon}
                <span className="ml-4">{item.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
