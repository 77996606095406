import React from "react";
import { Link } from 'react-router-dom'


function Card(prop) {
  return (
    <div className="bg-white rounded-lg h-auto w-auto p-3 shadow-2xl mb-6">
      <img
        src={`${prop.image_url}`}
        alt="application_image"
        className="mb-4 rounded-lg h-48 w-full object-fit"
      />
      <div className="flex flex-col gap-4 items-start space-between">
        <h2 className="darkerMain font-bold">{prop.name}</h2>
        <div className="flex gap-2">
          {prop.stack.map((item, index) => (
            <span key={index} className="small text-white rounded-lg bg-slate-400 p-1">{item}</span>
          ))}
        </div>
        <p className="text-sm">{prop.description}</p>
        <Link to={`/portfolio/${prop.id}`} className="bgMain text-white text-sm leading-6 font-medium py-2 px-3 rounded-lg self-center">View Project</Link>
      </div>

    </div>
  );
}

export default Card;
