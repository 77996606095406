import { FaFacebookF, FaGithub, FaInstagram, FaTwitter } from "react-icons/fa"
function Footer() {
   const year = new Date(Date.now()).getFullYear()

  return (
    <footer className="footer items-center p-4 bg-neutral text-neutral-content h-48">
      <div className="container mx-auto flex justify-between">

      <div>
          <h1>&copy; Copyright {year}. All rights are reserved.</h1>
      </div>

      <div className="flex gap-3 md:gap-10">
      <a href="https://www.facebook.com/ebuka.gerard/" target="_blank" rel="noreferrer" className="text-xl"><FaFacebookF/></a>
        <a href="https://www.instagram.com/ebuka.ng/" target="_blank" rel="noreferrer" className="text-xl"><FaInstagram/></a>
        <a href="https://github.com/ebuka-nwabueze" target="_blank" rel="noreferrer" className="text-xl"><FaGithub/></a>
        <a href="http://twitter.com/ebukaGN" target="_blank" rel="noreferrer" className="text-xl"><FaTwitter/></a>
      </div>

      </div>
    </footer>
  );
}

export default Footer;
