export const SiteData = [
  {
    projects: [
      {
        id: 1,
        name: "House Market Place",
        description:
          "This is an application for house sales and rent. It provides feature to list apartments either for sale or rent. It was built using react Js and Firebase for the backend.",
        github_url: "https://github.com/ebuka-nwabueze/house-marketplace-app",
        live_url: "https://house-marketplace-app-xi.vercel.app/",
        image_url:
          "https://res.cloudinary.com/dja8rc2qb/image/upload/v1660311530/portfolio_projects/Housemarketplace_image_url_ljm0gm.png",
        gif_url:
          "https://res.cloudinary.com/dja8rc2qb/image/upload/v1660311529/portfolio_projects/Housemarketplace_gif_url_vagitc.gif",
        stack: ["react", "firebase"],
      },
      {
        id: 2,
        name: "Ticketing System",
        description:
          "This application serves as a service desk portal. It is used to log requests and view the status of the request.",
        github_url: "https://github.com/ebuka-nwabueze/ticketing-system",
        live_url: "https://tech-support-app.herokuapp.com/",
        image_url:
          "https://res.cloudinary.com/dja8rc2qb/image/upload/v1660312076/portfolio_projects/ticketingSystem_image_url_tchsos.png",
        gif_url:
          "https://res.cloudinary.com/dja8rc2qb/video/upload/v1660312103/portfolio_projects/ticketingSystem_gif_url_cb3upz.mov",
        stack: ["react", "redux", "nodejs", "express"],
      },
      {
        id: 3,
        name: "Github Finder",
        description:
          "This application provides a way to search for users on Github and display some basic information from the user's profile. It was developed using Github's Developer API.",
        github_url: "https://github.com/ebuka-nwabueze/github-finder-app",
        live_url: "https://darling-marzipan-91e44f.netlify.app",
        image_url:
          "https://res.cloudinary.com/dja8rc2qb/image/upload/v1660310079/portfolio_projects/GithubFinder_image_url_ksshue.png",
        gif_url:
          "https://res.cloudinary.com/dja8rc2qb/image/upload/v1660310079/portfolio_projects/GithubFinder_image_url_ksshue.png",
        stack: ["react", "tailwindcss"],
      },
      {
        id: 4,
        name: "Hotelier",
        description: "This application is a reporting tool used for reporting requests. It provides overview of the request status.",
        github_url: "https://github.com/ebuka-nwabueze/Hotelier",
        live_url: "https://hotelieer.herokuapp.com/",
        image_url:
          "https://res.cloudinary.com/dja8rc2qb/image/upload/v1660313020/portfolio_projects/Hotelier_image_url_bres90.png",
        gif_url:
          "https://res.cloudinary.com/dja8rc2qb/image/upload/v1660313020/portfolio_projects/Hotelier_gif_url_worbmd.gif",
        stack: [
          "react",
          "typescript",
          "mongodb",
          "graphql",
          "nodejs",
          "express",
        ],
      },
      {
        id: 5,
        name: "Feedback App",
        description:
          "This application provides a way to rate services by creating, editing and deleting. It also gives the average rating score of all feedbacks.",
        github_url: "https://github.com/ebuka-nwabueze/feedback-app",
        live_url: "https://boring-turing-76e8cb.netlify.app/",
        image_url:
          "https://res.cloudinary.com/dja8rc2qb/image/upload/v1660313451/portfolio_projects/FeedbackApp_image_url_ojh4ei.png",
        gif_url:
          "https://res.cloudinary.com/dja8rc2qb/image/upload/v1660313452/portfolio_projects/feedbackApp_gif_url_ldyxe4.gif",
        stack: [
          "react",
          "typescript",
          "mongodb",
          "graphql",
          "nodejs",
          "express",
        ],
      },
      {
        id: 6,
        name: "Bill Splitter App",
        description:
          "This application is for the purpose of bill splitting. An total bill amount can be entered and number of people, the app calculates the percentage tip and the amount each person has to pay.",
        github_url: "https://github.com/ebuka-nwabueze/splitter",
        live_url:
          "https://62ebac746739510c3c4d24a7--eloquent-duckanoo-1d7573.netlify.app/",
        image_url:
          "https://res.cloudinary.com/dja8rc2qb/image/upload/v1660313607/portfolio_projects/billsplitter_image_url_prwwea.png",
        gif_url:
          "https://res.cloudinary.com/dja8rc2qb/image/upload/v1660313851/portfolio_projects/billSplitter_gif_url_rnsrne.gif",
        stack: ["react", "typescript"],
      },
    ],
  },
];
