import { useState } from "react";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const { name, email, subject, message } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <div className="height-ful">
      <h1 className="brand text-4xl mb-5">Get in Touch</h1>
      <p className="mb-5">
        I'm always interested in hearing about new projects and opportunities.
      </p>

      <div className="flex flex-col gap-2 md:gap-8 md:flex-row justify-content overflow-auto">
        <div className="md:w-4/12">
          <h1 className="font-bold text-xl mb-5">
            Let's talk about everything
          </h1>
          <p>
            Don't like forms? Send me an 
            <a href="mailto:ebukanwabueze@gmail.com"> email</a>
          </p>
        </div>

        <div className="md:w-6/12">
          <form onSubmit={onSubmit}>

            <div className="md:flex flex-row justify-content items-center gap-4 w-full">
              <div className="form-group flex-auto">
                <input
                  className="nameInput"
                  type="text"
                  value={name}
                  placeholder="Your name"
                  id="name"
                  onChange={onChange}
                />
              </div>
              <div className="form-group flex-auto">
                <input
                  className="emailInput"
                  type="text"
                  value={email}
                  placeholder="Your email"
                  id="email"
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="form-group">
                <input
                  className="subjectInput"
                  type="text"
                  value={subject}
                  placeholder="Subject"
                  id="subject"
                  onChange={onChange}
                />
              </div>
            <div className="form-group">
                <textarea
                  className="messageInput"
                  type="text"
                  value={message}
                  placeholder="Message"
                  id="message"
                  onChange={onChange}
                />
              </div>

            <div className="form-group">
              <button className="btn normal-case rounded-2xl" type="submit">Send Message</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
