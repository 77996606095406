import { FaBookReader, FaBuilding, FaLanguage, FaUniversity, FaUserCheck, FaCode, FaCubes, FaCodeBranch } from "react-icons/fa"
import { Link } from 'react-router-dom'


function About() { 
  return (
    <div className="py-10">
      <h1 className="brand text-4xl mb-5">Meet Ebuka Nwabueze</h1>
      <div className="md:flex md:gap-3 ">
        
        <div className="md:mb-0 mb-5 w-96 h-96"
        > 
        <img src={`https://res.cloudinary.com/dja8rc2qb/image/upload/v1648940635/branding/personal1_ejliux.jpg`} alt="myImage" style={{width:"100%", height:"100%"}}/>
        
        
        </div>

        <div className="">
          <h1 className="mb-3 font-bold">Who am I?</h1>

          <div className="flex gap-3 justify-content items-center mb-3">
            <FaUserCheck className="text-5xl md:text-2xl"/>
            <h1>A Software Engineer with hands on experience developing web applications.</h1>
          </div>

          <div className="flex gap-3 justify-content items-center mb-3">
            <FaUniversity className="text-5xl md:text-2xl"/>
            <h1>Currently studying for a Master's degree in IT Project Management, FH Dortmund.</h1>
          </div>

          <div className="flex gap-3 justify-content items-center mb-3">
            <FaBookReader className="text-2xl"/>
            <h1>Love to read, travel and watch football.</h1>
          </div>

          <div className="flex gap-3 justify-content items-center mb-3">
            <FaLanguage className="text-2xl"/>
            <h1>Speaks English and German.</h1>
          </div>

          <div className="flex gap-3 justify-content items-center mb-3">
            <FaBuilding className="text-2xl"/>
            <h1>Currently working at Trivago GmBH.</h1>
          </div>

        <h1 className="mb-5 font-bold pt-5">What I do?</h1>

        <div className="flex flex-col gap-4 justify-content items-center mb-5 md:flex-row">
            <div className="flex flex-col justify-content items-center justify-around shadow-lg w-48 h-32 rounded-lg hover:bg-base-content hover:text-white">
              <FaCode className="text-3xl"/>
              <h1 className="text-center">Front-End Web Development</h1>
            </div>

            <div className="flex flex-col justify-content items-center justify-around shadow-lg w-48 h-32 rounded-lg hover:bg-base-content hover:text-white">
              <FaCodeBranch className="text-3xl"/>
              <h1 className="text-center">Back-End / API Development</h1>
            </div>

            <div className="flex flex-col justify-content items-center justify-around shadow-lg w-48 h-32 rounded-lg hover:bg-base-content hover:text-white">
              <FaCubes className="text-3xl"/>
              <h1 className="text-center">Full-Stack Web Development</h1>
            </div>
        </div>

          <p>I'm super excited you are here. Feel free <Link className="font-bold" to="/contact">to reach out to me</Link> with any project ideas you have or to just say hello.</p>
        </div>

      </div>
    </div>
  )
}

export default About
