import TypeWriterEffect from 'react-typewriter-effect';

function Home() {
  return (
    <div
      className="hero text-left height-full"
      style={{backgroundImage: `url(https://res.cloudinary.com/dja8rc2qb/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1648941139/branding/laptop_desk_du2ooc.jpg)`}}
    >
      <div className="hero-overlay text-right bg-opacity-60"></div>
      <div className="hero-content  text-neutral-content">
        <div className="max-w-md">
          <h1 className="mb-5 text-5xl font-bold"><span className="brand">Ebuka Nwabueze</span></h1>
          <div className="mb-5">
          <TypeWriterEffect
            textStyle={{ fontSize: '1.5em',}}
            startDelay={100}
            cursorColor="white"
            multiText={["Software Engineer"]}
            typeSpeed={150}
            
          />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
