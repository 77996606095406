import React from 'react'
import { AiFillHome } from 'react-icons/ai'
import { FaRegAddressBook } from 'react-icons/fa'
import { FaLaptopCode } from 'react-icons/fa'
import { IoMdPerson } from 'react-icons/io'
import { GiNotebook } from 'react-icons/gi'

export const SideBarData = [
  {
    title: "Home",
    path: "/",
    icon: <AiFillHome/>,
    cName: "nav-text"
  },
  {
    title: "About",
    path: "/about",
    icon: <IoMdPerson/>,
    cName: "nav-text"
  },
  {
    title: "Resume",
    path: "/resume",
    icon: <GiNotebook/>,
    cName: "nav-text"
  },
  {
    title: "Portfolio",
    path: "/portfolio",
    icon: <FaLaptopCode/>,
    cName: "nav-text"
  },
  {
    title: "Contact",
    path: "/contact",
    icon: <FaRegAddressBook/>,
    cName: "nav-text"
  },
]