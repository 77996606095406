
function Resume() {
  return (
    <div className="">
      <div className="mb-6">
        <h1 className="brand text-4xl mb-2">Resume</h1>
        <p>Learn more about my skills, qualifications and experiences.</p>
      </div>

      <div className="md:flex items-start">
        <div className="mb-6 md:w-3/12">
          <h2 className="font-bold text-xl">Work Experience</h2>
          <p className="text-sm">PRESENT AND PREVIOUS JOBS</p>
        </div>

        <div>
          <div className="mb-5 md:w-9/12">
            <h1 className="lightMain">Juli 2021 - Present</h1>
            <h1 className="font-bold">APPLICATION DEVELOPER</h1>
            <p className="text-sm">Trivago GmBH</p>
            <ul className="list-disc list-inside">
              <li className="text-sm">
                Developing internal tools to facilitate and improve business
                process flow.
              </li>
            </ul>
          </div>

          <div className="mb-5">
            <h1 className="lightMain">August 2020 - June 2021</h1>
            <h1 className="font-bold">PROJECT MANAGER</h1>
            <p className="text-sm">Trivago GmBH</p>
            <ul className="list-disc list-inside">
              <li className="text-sm">
                Created project plans and strategies to achieve set goals.
              </li>
              <li className="text-sm">
              Monitored and improved internal software processes.
              </li>
              <li className="text-sm">
                Developed and led initiates to create new tools in the
                organization.
              </li>
              <li className="text-sm">
                Executed day-to-day service request from internal teams.
              </li>
            </ul>
          </div>

          <div className="mb-5">
            <h1 className="lightMain">March 2019 - June 2020</h1>
            <h1 className="font-bold">WEB DEVELOPER</h1>
            <p className="text-sm">Orthoex Nigeria</p>
            <ul className="list-disc list-inside">
              <li className="text-sm">
                Designing and building the front-end interface of website.
              </li>
              <li className="text-sm">
                Created architecture for the live website.
              </li>
              <li className="text-sm">
                Analyzed performance indicators and performance of live website.
              </li>
              <li className="text-sm">
                Troubleshooted content issues on the published website.
              </li>
            </ul>
          </div>

          <div className="mb-5">
            <h1 className="lightMain">January 2017 - February 2019</h1>
            <h1 className="font-bold">APPLICATION SUPPORT ENGINEER</h1>
            <p className="text-sm">Sterling Bank Plc</p>
            <ul className="list-disc list-inside">
              <li className="text-sm">
                Managed Microsoft Active Directory, Office 365.
              </li>
              <li className="text-sm">
                Classified and analysed of generated reports from the Core
                Banking application
              </li>
              <li className="text-sm">
                Monitored Database and Network using Orion Solar winds, SQL,
                Oracle.
              </li>
              <li className="text-sm">
                Implemented Software in Enterprise level Architecture.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="md:flex md:items-start mt-16">
        <div className="mb-6 md:w-3/12">
          <h2 className="font-bold text-xl">Education</h2>
          <p className="text-sm">ACADEMIC CAREER</p>
        </div>

        <div>
          <div className="mb-5 md:w-9/12">
            <h1 className="font-bold">MA. IT PROJECT MANAGEMENT</h1>
            <p className="text-sm">
              University of Applied Science and Arts, Dortmund, Germany.
            </p>
            <p className="my-3 text-sm rounded backMain inline-block p-1.5">
              Sep 2019 - Present
            </p>
            <ul className="list-disc list-inside">
              <li className="text-sm">
                Relevant Coursework includes: Traditonal and Agile Project
                Management, Managing Digital Change, Information and Data
                analytics, Leadership and Teams.
              </li>
            </ul>
          </div>

          <div className="mb-5">
            <h1 className="font-bold">
              BSC. INFORMATION MANAGEMENT TECHNOLOGY
            </h1>
            <p className="text-sm">
              Federal University of Technology, Owerri, Nigeria
            </p>
            <p className="my-3 text-sm rounded backMain inline-block p-1.5">
              Sep 2009 - Oct 2014
            </p>
            <ul className="list-disc list-inside">
              <li className="text-sm">
                Relevant Coursework includes: Software Engineering Fundamentals,
                Algorithms and Data Structures, Operations Research, Database Management, Network systems.
              </li>
            </ul>
          </div>

          <div>
            <h1 className="font-bold">
              DIPLOMA. NETWORK MANAGEMENT AND TELECOMMUNICATIONS
            </h1>
            <p className="text-sm">
              Telecommunications Training Centre, Lagos, Nigeria
            </p>
            <p className="my-3 text-sm rounded backMain inline-block p-1.5">
              May 2016 - Nov 2016
            </p>
            <ul className="list-disc list-inside">
              <li className="text-sm">
                Relevant Coursework includes: Advanced Networking, RF Engineering.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resume;
